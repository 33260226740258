import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { RichText } from 'prismic-reactjs';
import PageContainer from '../components/pageContainer.js';
import Image from "gatsby-image";

const ContactUsContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #064f71;
`;

const ContactUsText = styled.p`
  text-align: center;
  font-size: 3rem;
  width: 70%;
  margin: 0 auto;
`;

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
       catering: file(relativePath: { eq: "catering.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        site {
          siteMetadata {
            siteUrl
          }
        }
        prismic {
          allHome_pages {
            edges {
              node {
                title
                description
                seo_image
                seo_imageSharp {
                  childImageSharp {
                    fixed(width: 1024) {
                      ...GatsbyImageSharpFixed_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { node } = data.prismic.allHome_pages.edges.slice(0, 1).pop();
      if (!node) {
        return null;
      }

      const { title, description, seo_imageSharp } = node;

      const meta = [];

      if (seo_imageSharp) {
        meta.push({
          name: `og:image`,
          content:
            data.site.siteMetadata.siteUrl +
            seo_imageSharp.childImageSharp.fixed.src,
        });
      }

      return (
        <Layout>
          <SEO
            title={title ? RichText.asText(title) : null}
            description={description ? RichText.asText(description) : null}
            meta={meta}
          />
          <ContactUsContainer>
            <PageContainer>
              {data.catering && <Image fluid={data.catering.childImageSharp.fluid} />}
              <ContactUsText>
                Email{' '}
                <a href={'mailto:catering@honbo.com'}>catering@honbo.com</a> for
                more information
              </ContactUsText>
            </PageContainer>
          </ContactUsContainer>
        </Layout>
      );
    }}
  />
);

export default IndexPage;
